import * as Configuration from './configuration'
import * as Data from './data'
import * as FormComponents from './form-components'
import * as Herz from '@rushplay/herz'
import * as Payments from './payments'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Reselect from 'reselect'
import * as ThemeUi from 'theme-ui'

import {FieldBuilder} from './field-builder'

/**
 * Selectors for prefilling data into fields of specific payment providers
 * @param {Object} state Redux state
 * @returns {Object} initial values for specific scopes
 */
const getInitialValues = Reselect.createStructuredSelector({
  '#/definitions/PiqWebredirectPneDeposit/properties/service': () => 'PNE',
  '#/definitions/PiqAstropaybankPixWithdrawal/properties/service': () => 'PIX',
  '#/definitions/PiqAstropaybankD24indiaWithdrawal/properties/service': () =>
    'D24INDIA',
  '#/definitions/PiqAstropaycardGoDeposit/properties/service': () => 'GO',
  '#/definitions/PiqAstropaycardNbDeposit/properties/service': () => 'NB',
  '#/definitions/PiqAstropaycardPhDeposit/properties/service': () => 'PH',
  '#/definitions/PiqAstropaycardTmDeposit/properties/service': () => 'TM',
  '#/definitions/PiqAstropaycardUiDeposit/properties/service': () => 'UI',
  '#/definitions/PiqBankBlDeposit/properties/service': () => 'BL',
  '#/definitions/PiqBankIxDeposit/properties/service': () => 'IX',
  '#/definitions/PiqBankJcDeposit/properties/service': () => 'JC',
  '#/definitions/PiqBankBtvoucherDeposit/properties/service': () => 'BTVOUCHER',
  '#/definitions/PiqBankBtvoucherAtmDeposit/properties/service': () =>
    'BTVOUCHER_ATM',
  '#/definitions/PiqBankPpbtvoucherDeposit/properties/service': () =>
    'PPBTVOUCHER',
  '#/definitions/PiqBankibanOnlineueberweisenDeposit/properties/service': () =>
    'ONLINEUEBERWEISEN',
  '#/definitions/PiqBankibanBriteWithdrawal/properties/service': () => 'BRITE',
  '#/definitions/PiqBankibanEbpWithdrawal/properties/service': () => 'EBP',
  '#/definitions/PiqWebredirectMacropayDeposit/properties/service': () =>
    'MACROPAY',
  '#/definitions/PiqBankibanNodapayWithdrawal/properties/service': () =>
    'NODAPAY',
  '#/definitions/PiqBankBriteWithdrawal/properties/service': () => 'BRITE',
  '#/definitions/PiqBankBriteDeposit/properties/service': () => 'BRITE',
  '#/definitions/PiqBankNbDeposit/properties/service': () => 'NB',
  '#/definitions/PiqBankUiDeposit/properties/service': () => 'UI',
  '#/definitions/PiqBankInovapayDeposit/properties/service': () => 'INOVAPAY',
  '#/definitions/PiqBankInteracDeposit/properties/service': () => 'INTERAC',
  '#/definitions/PiqBankNetBankingDeposit/properties/service': () =>
    'NET_BANKING',
  '#/definitions/PiqBankRedeemVoucherDeposit/properties/service': () =>
    'REDEEM_VOUCHER',
  '#/definitions/PiqBankParamountDeposit/properties/service': () => 'PARAMOUNT',
  '#/definitions/PiqBankPixDeposit/properties/service': () => 'PIX',
  '#/definitions/PiqBankUpiDeposit/properties/service': () => 'UPI',
  '#/definitions/PiqBankVoltDeposit/properties/service': () => 'VOLT',
  '#/definitions/PiqBankVoltWithdrawal/properties/service': () => 'VOLT',
  '#/definitions/PiqBankWalletDeposit/properties/service': () => 'WALLET',
  '#/definitions/PiqCryptocurrencyBitpaceDeposit/properties/service': () =>
    'BITPACE',
  '#/definitions/PiqCryptocurrencyBitpaceWithdrawal/properties/service': () =>
    'BITPACE',
  '#/definitions/PiqCryptocurrencyCryptopayDeposit/properties/service': () =>
    'CRYPTOPAY',
  '#/definitions/PiqCryptocurrencyCryptopayWithdrawal/properties/service': () =>
    'CRYPTOPAY',
  '#/definitions/PiqInovapaybankPixWithdrawal/properties/service': () => 'PIX',
  '#/definitions/PiqPproIdealDeposit/properties/service': () => 'IDEAL',
  '#/definitions/PiqWebredirectOpDeposit/properties/service': () => 'OP',
  '#/definitions/PiqWebredirectApplepayDeposit/properties/service': () =>
    'APPLEPAY',
  '#/definitions/PiqWebredirectGooglepayDeposit/properties/service': () =>
    'GOOGLEPAY',
  '#/definitions/PiqWebredirectColibrixGooglepayDeposit/properties/service': () =>
    'COLIBRIX_GOOGLEPAY',
  '#/definitions/PiqWebredirectColibrixApplepayDeposit/properties/service': () =>
    'COLIBRIX_APPLEPAY',
  '#/definitions/PiqWebredirectEbpDeposit/properties/service': () => 'EBP',
  '#/definitions/PiqWebredirectTrustlyDeposit/properties/service': () =>
    'TRUSTLY',
  '#/definitions/PiqWebredirectNodapayDeposit/properties/service': () =>
    'NODAPAY',
  '#/definitions/PiqWebredirectBoletoDeposit/properties/service': () =>
    'BOLETO',
  '#/definitions/PiqWebredirectBoletoPixDeposit/properties/service': () =>
    'BOLETO_PIX',
  '#/definitions/PiqWebredirectBtDeposit/properties/service': () => 'BT',
  '#/definitions/PiqEzeebillBtjpnWithdrawal/properties/service': () => 'BTJPN',
  '#/definitions/PiqWebredirectJpayDeposit/properties/service': () => 'JPAY',
  '#/definitions/PiqWebredirectOnrampDeposit/properties/service': () =>
    'ONRAMP',
  '#/definitions/PiqWebredirectPixOnlineDeposit/properties/service': () =>
    'PIX_ONLINE',
  '#/definitions/PiqWebredirectQuickbitDeposit/properties/service': () =>
    'QUICKBIT',
  '#/definitions/PiqWebredirectZotapayDeposit/properties/service': () =>
    'ZOTAPAY',
  '#/definitions/PiqWebredirectSofortDeposit/properties/service': () =>
    'SOFORT',
  '#/definitions/PiqWebredirectSumopayDeposit/properties/service': () =>
    'SUMOPAY',
  '#/definitions/PiqWebredirectQaicashDeposit/properties/service': () =>
    'QAICASH',
  '#/definitions/PiqWebredirectPointinoutDeposit/properties/service': () =>
    'POINTINOUT_EWALLET',
  '#/definitions/PiqWebredirectQpointsDeposit/properties/service': () =>
    'LOCAL_EWALLET',
  '#/definitions/PiqWebredirectIbDeposit/properties/service': () => 'IB',
  '#/definitions/PiqWebredirectZotacardDeposit/properties/service': () =>
    'ZOTACARD',
  '#/definitions/PiqWebredirectOnrampWithdrawal/properties/service': () =>
    'ONRAMP',
  '#/definitions/PiqWebredirectSofortuberweisungDeposit/properties/service': () =>
    'SOFORTUBERWEISUNG',
  '#/definitions/PiqWebredirectOnlineBankingDeposit/properties/service': () =>
    'ONLINE_BANKING',
  '#/definitions/PiqWebredirectPointinoutWithdrawal/properties/service': () =>
    'POINTINOUT_EWALLET',
  '#/definitions/PiqWebredirectQpointsWithdrawal/properties/service': () =>
    'LOCAL_EWALLET',
  '#/definitions/PiqWebredirectQaicashWithdrawal/properties/service': () =>
    'QAICASH',
  '#/definitions/PiqBanklocalSumopayWithdrawal/properties/service': () =>
    'SUMOPAY',
  '#/definitions/PiqWebredirectFlykkDeposit/properties/service': () => 'FLYKK',
  '#/definitions/PiqWebredirectFinsupportDeposit/properties/service': () =>
    'FINSUPPORT',
  '#/definitions/PiqCryptocurrencyFinsupportDeposit/properties/service': () =>
    'FINSUPPORT',
  '#/definitions/PiqWebredirectPagavaDeposit/properties/service': () =>
    'PAGAVA',
  '#/definitions/PiqBanklocalPagavaWithdrawal/properties/service': () =>
    'PAGAVA',
  '#/definitions/PiqWebredirectBdbanksDeposit/properties/service': () =>
    'BDBANKS',
  '#/definitions/PiqWebredirectUpiDeposit/properties/service': () => 'UPI',
  '#/definitions/PiqWebredirectMastercardDeposit/properties/service': () =>
    'MASTERCARD',
  '#/definitions/PiqWebredirectRupayDeposit/properties/service': () => 'RUPAY',
  '#/definitions/PiqWebredirectOnlineDebitDeposit/properties/service': () =>
    'ONLINE_DEBIT',
  '#/definitions/PiqWebredirectUpiH5Deposit/properties/service': () => 'UPI_H5',
  '#/definitions/PiqWebredirectPaytmWalletDeposit/properties/service': () =>
    'PAYTM_WALLET',
  '#/definitions/PiqWebredirectPhonepeWalletDeposit/properties/service': () =>
    'PHONEPE_WALLET',
  '#/definitions/PiqWebredirectMobikwikWalletDeposit/properties/service': () =>
    'MOBIKWIK_WALLET',
  '#/definitions/PiqWebredirectAirtelWalletDeposit/properties/service': () =>
    'AIRTEL_WALLET',
  '#/definitions/PiqWebredirectJioWalletDeposit/properties/service': () =>
    'JIO_WALLET',
  '#/definitions/PiqWebredirectCreditCardDeposit/properties/service': () =>
    'CREDIT_CARD',
  '#/definitions/PiqWebredirectUpiWithdrawal/properties/service': () => 'UPI',
  '#/definitions/PiqWebredirectLocalBankTransferWithdrawal/properties/service': () =>
    'LOCAL_BANK_TRANSFER',
  '#/definitions/PiqWebredirectVisaDeposit/properties/service': () => 'VISA',
  '#/definitions/PiqWebredirectSticpayDeposit/properties/service': () =>
    'STICPAY',
  '#/definitions/PiqWebredirectPaytmDeposit/properties/service': () => 'PayTM',
  '#/definitions/PiqWebredirectImpsDeposit/properties/service': () => 'IMPS',
  '#/definitions/PiqWebredirectGpayDeposit/properties/service': () => 'GPAY',
  '#/definitions/PiqWebredirectPhonepeDeposit/properties/service': () =>
    'PHONEPE',
  '#/definitions/PiqWebredirectDirectPaymentDeposit/properties/service': () =>
    'DIRECT_PAYMENT',
  '#/definitions/PiqWebredirectTigerpayDeposit/properties/service': () =>
    'TIGERPAY',
  '#/definitions/PiqWebredirectBtbDeposit/properties/service': () => 'BTB',
  '#/definitions/PiqWebredirectBtbAutoDeposit/properties/service': () =>
    'BTB_AUTO',
  '#/definitions/PiqWebredirectTigerpayWithdrawal/properties/service': () =>
    'TIGERPAY',
  '#/definitions/PiqBanklocalBtbWithdrawal/properties/service': () => 'BTB',
  '#/definitions/PiqBanklocalInpayjpWithdrawal/properties/service': () =>
    'INPAYJP',
  '#/definitions/PiqBanklocalUpiWithdrawal/properties/service': () => 'UPI',
  '#/definitions/PiqBanklocalLocalBankTransferWithdrawal/properties/service': () =>
    'LOCAL_BANK_TRANSFER',
  '#/definitions/PiqBanklocalInpayjpWithdrawal/properties/beneficiaryName':
    Data.getFullName,
  '#/definitions/PiqGate2waywalletPaytmWithdrawal/properties/service': () =>
    'PayTM',
  '#/definitions/PiqBanklocalImpsWithdrawal/properties/service': () => 'IMPS',
  '#/definitions/PiqBanklocalInterkassaWithdrawal/properties/service': () =>
    'INTERKASSA',
  '#/definitions/PiqBanklocalVipwithdrawalJpWithdrawal/properties/service': () =>
    'VIPWITHDRAWAL_JP',
  '#/definitions/PiqInteracWithdrawal/properties/email': Data.getEmail,
  '#/definitions/PiqInteracWithdrawal/properties/mobile': Data.getPhoneNumber,
  '#/definitions/PiqInterkassawalletWalletDeposit/properties/service': () =>
    'WALLET',
  '#/definitions/PiqInterkassawalletUpiDeposit/properties/service': () => 'UPI',
  '#/definitions/PiqInterkassawalletUpiWithdrawal/properties/service': () =>
    'UPI',
  '#/definitions/PiqBankintlInpayWithdrawal/properties/beneficiaryName':
    Data.getFullName,
  '#/definitions/PiqBankintlInpayWithdrawal/properties/beneficiaryStreet':
    Data.getStreet,
  '#/definitions/PiqBankintlInpayWithdrawal/properties/beneficiaryZip':
    Data.getPostalCode,
  '#/definitions/PiqBankintlInpayWithdrawal/properties/beneficiaryCity':
    Data.getCity,
  '#/definitions/PiqBankintlInpayWithdrawal/properties/beneficiaryState':
    Data.getState,
  '#/definitions/PiqBankintlInpayWithdrawal/properties/service': () => 'INPAY',
  '#/definitions/PiqBankintlNzdWithdrawal/properties/service': () => 'NZD',
  '#/definitions/PiqBankintlNetbankingWithdrawal/properties/beneficiaryName':
    Data.getFullName,
  '#/definitions/PiqBankintlNetbankingWithdrawal/properties/beneficiaryStreet':
    Data.getStreet,
  '#/definitions/PiqBankintlNetbankingWithdrawal/properties/beneficiaryZip':
    Data.getPostalCode,
  '#/definitions/PiqBankintlNetbankingWithdrawal/properties/beneficiaryCity':
    Data.getCity,
  '#/definitions/PiqBankintlNetbankingWithdrawal/properties/beneficiaryState':
    Data.getState,
  '#/definitions/PiqBankibanWithdrawal/properties/beneficiaryName':
    Data.getFullName,
  '#/definitions/PiqBankintlWithdrawal/properties/beneficiaryName':
    Data.getFullName,
  '#/definitions/PiqCreditcardDeposit/properties/cardHolder': Data.getFullName,
  '#/definitions/PiqCreditcardWithdrawal/properties/cardHolder':
    Data.getFullName,
  '#/definitions/PiqCreditcardVisaDeposit/properties/cardHolder':
    Data.getFullName,
  '#/definitions/PiqCreditcardVisaDeposit/properties/service': () => 'VISA',
  '#/definitions/PiqCreditcardMastercardDeposit/properties/cardHolder':
    Data.getFullName,
  '#/definitions/PiqCreditcardMastercardDeposit/properties/service': () =>
    'MASTERCARD',
  '#/definitions/PiqCreditcardRupayDeposit/properties/cardHolder':
    Data.getFullName,
  '#/definitions/PiqCreditcardRupayDeposit/properties/service': () => 'RUPAY',
  '#/definitions/PiqPay4funWithdrawal/properties/email': Data.getEmail,
  '#/definitions/PiqPayretailersWithdrawal/properties/beneficiaryName':
    Data.getFullName,
  '#/definitions/PiqPayretailersWithdrawal/properties/documentType': () => '1',
  '#/definitions/PiqPayretailersPixWithdrawal/properties/beneficiaryName':
    Data.getFullName,
  '#/definitions/PiqPayretailersPixWithdrawal/properties/documentType': () =>
    '1',
  '#/definitions/PiqPayretailersPixWithdrawal/properties/service': () => 'PIX',
  '#/definitions/PiqMuchbetterDeposit/properties/phoneNumber':
    Data.getPhoneNumber,
  '#/definitions/PiqMuchbetterWithdrawal/properties/phoneNumber':
    Data.getPhoneNumber,
  '#/definitions/PiqWebredirectDeposit/properties/email': Data.getEmail,
  '#/definitions/ProjsBankidAuth/properties/bank_id_type':
    Configuration.getDefaultProjsBankidType,
  '#/definitions/ProjsBankidAuth/properties/personal_number': Data.getSsn,
  '#/definitions/ProjsSwishDeposit/properties/bank_id_type':
    Configuration.getDefaultProjsBankidType,
  '#/definitions/ProjsSwishDeposit/properties/personal_number': Data.getSsn,
  '#/definitions/ProjsSwishDeposit/properties/phone_number':
    Data.getPhoneNumber,
  '#/definitions/PiqMifinityUnionpayDeposit/properties/service': () =>
    'UNIONPAY',
  '#/definitions/PiqMifinityKlarnaDeposit/properties/service': () => 'KLARNA',
  '#/definitions/PiqWebredirectSparkasseDeposit/properties/service': () =>
    'NODAPAY',
  '#/definitions/PiqWebredirectSparkasseDeposit/properties/providerId': () =>
    'sparkasse_de',
  '#/definitions/PiqWebredirectVolksbankenDeposit/properties/service': () =>
    'NODAPAY',
  '#/definitions/PiqWebredirectVolksbankenDeposit/properties/providerId': () =>
    'volksbanken_raiffeisenbanken_de',
  '#/definitions/PiqWebredirectDeutschebankDeposit/properties/service': () =>
    'NODAPAY',
  '#/definitions/PiqWebredirectDeutschebankDeposit/properties/providerId': () =>
    'deutsche_bank_de',
  '#/definitions/PiqWebredirectPostbankDeposit/properties/service': () =>
    'NODAPAY',
  '#/definitions/PiqWebredirectPostbankDeposit/properties/providerId': () =>
    'postbank_deutsche_postbank_de',
  '#/definitions/PiqWebredirectCommerzbankDeposit/properties/service': () =>
    'NODAPAY',
  '#/definitions/PiqWebredirectCommerzbankDeposit/properties/providerId': () =>
    'commerzbank_de',
  '#/definitions/PiqWebredirectDeutschekreditbankDeposit/properties/service': () =>
    'NODAPAY',
  '#/definitions/PiqWebredirectDeutschekreditbankDeposit/properties/providerId': () =>
    'dkb_deutsche_kreditbank_aktiengesellschaft_de',
  '#/definitions/PiqWebredirectGatowayDeposit/properties/service': () =>
    'GATOWAY',
  '#/definitions/PiqWebredirectGate2wayDeposit/properties/service': () =>
    'GATE2WAY',
  '#/definitions/PiqWebredirectGate2wayskrillDeposit/properties/service': () =>
    'GATE2WAYSKRILL',
  '#/definitions/PiqWebredirectGate2waynetellerDeposit/properties/service': () =>
    'GATE2WAYNETELLER',
  '#/definitions/PiqWebredirectGate2waygiropayDeposit/properties/service': () =>
    'GATE2WAYGIROPAY',
  '#/definitions/PiqWebredirectGate2waypaysafecardDeposit/properties/service': () =>
    'GATE2WAYPAYSAFECARD',
  '#/definitions/PiqWebredirectGate2waypaysafecashDeposit/properties/service': () =>
    'GATE2WAYPAYSAFECASH',
  '#/definitions/PiqWebredirectGate2waysofortDeposit/properties/service': () =>
    'GATE2WAYSOFORT',
  '#/definitions/PiqWebredirectGate2waywebpayzDeposit/properties/service': () =>
    'GATE2WAYWEBPAYZ',
  '#/definitions/TransferworldTransferworldWithdrawal/properties/beneficiaryName':
    Data.getFullName,
  '#/definitions/PiqWebredirectMandatoDeposit/properties/service': () =>
    'MANDATO',
  '#/definitions/PiqWebredirectMandatoWithdrawal/properties/service': () =>
    'MANDATO',
})

/**
 * Component handles payment details
 * Extra input needed from the user to start a transaction
 * @component PaymentDetails
 * @returns {ReactNode} Form with various controllers
 */
export function PaymentDetails() {
  const {transactionType} = Payments.useTransactionContext()
  const initialValues = ReactRedux.useSelector(getInitialValues)
  const translate = Herz.I18n.useTranslate(
    () => [`payment-details.${transactionType}.cta`],
    [transactionType]
  )

  return (
    <ThemeUi.Box
      sx={{
        display: 'grid',
        gridGap: 1,
        alignContent: 'start',
        alignItems: 'start',
      }}
    >
      <FieldBuilder initialValues={initialValues} name="payment-details" />
      <ThemeUi.Box sx={{pt: 1, justifySelf: 'center'}}>
        <FormComponents.SubmitButton>
          {translate(`payment-details.${transactionType}.cta`)}
        </FormComponents.SubmitButton>
      </ThemeUi.Box>
    </ThemeUi.Box>
  )
}
